<template>
  <div>
    <div class="float-md-right d-none d-md-block">
      <b-navbar-nav class="nav align-items-center ml-auto">
        <b-nav-item-dropdown
          right
          toggle-class="d-flex align-items-center dropdown-user-link"
          class="dropdown-user"
        >
          <template #button-content>
            <div class="d-sm-flex d-none user-nav">
              <p
                class="user-name mb-0"
                style="color: #6e6b7b;font-size: 12px;"
              >
                {{ userData ? userData.name : '' }}
              </p>
            </div>
          </template>
          <b-dropdown-item
            link-class="d-flex align-items-center"
            @click="logout"
          >
            <feather-icon
              size="16"
              icon="LogOutIcon"
              class="mr-50"
            />
            <span>Sair</span>
          </b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </div>
    <b-tabs
      pills
    >
      <!--<b-tab
        :title-link-class="'icon-panel-home'"
        @click="openMenuPanel"
      >
        <template #title>
          <feather-icon
            icon="ArrowLeftIcon"
            size="19"
          />
        </template>
      </b-tab>-->
      <b-tab
        title="Painel Prosai"
        active
      >
        <iframe
          style="border:0;"
          width="100%"
          height="800"
          src="https://app.powerbi.com/view?r=eyJrIjoiOGJhOWE1ZWItM2QyNy00YzA4LWI4Y2YtNGMwYWRjNTkyMDE4IiwidCI6Ijg1NDczOTk4LTFmODEtNDAxMS1iYzk3LTg3YWUwNGU2MTIwNCJ9&pageName=ReportSection"
          frameborder="0"
          allowFullScreen="true"
        />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import { initialAbility } from '@/libs/acl/config'
import store from '@/store'
import useJwt from '@/auth/jwt/useJwt'

export default {
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  beforeCreate() {
    store.commit('appConfig/UPDATE_NAVBAR_CONFIG', { type: 'hidden' })
    store.commit('appConfig/UPDATE_FOOTER_CONFIG', { type: 'hidden' })
    store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true)
  },
  beforeRouteLeave(to, from, next) {
    store.commit('appConfig/UPDATE_NAVBAR_CONFIG', { type: 'floating' })
    store.commit('appConfig/UPDATE_FOOTER_CONFIG', { type: 'show' })
    store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', false)
    next()
  },
  methods: {
    openMenuPanel() {
      router.push({ name: 'panel' })
    },
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')

      // Reset ability
      this.$ability.update(initialAbility)

      // Redirect to login page
      this.$router.push({ name: 'prosai' })
    },
  },
}
</script>
<style>
  [dir] .nav-pills .nav-link {
    padding: 0.2rem 1rem;
    border: 1px solid transparent;
  }
  .icon-panel-home {
    padding: 0.1rem 0.8rem 0rem 0rem !important
  }
</style>
